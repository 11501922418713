import ButtonPink from "../../../core/button-pink";
import "../homepage.css";
import { Link } from "react-scroll";

function Banner1() {
  return (
    <div className="banner1-background relative flex justify-end items-center pr-4  md:pr-24">
      <div className="absolute bg-white lg:bg-transparent h-screen w-screen opacity-80 lg:opacity-100"></div>
      <div className="content flex flex-col items-end ">
        <img
          className="absolute top-0 right-0 w-[9rem]"
          src="/images/banner/Dots.svg"
        />
        <h2 className="z-10 text-[2.5rem] lg:text-[4rem]  font-[800] font-lato whitespace-nowrap">
          <span className="outline-font text-white">VIDEO</span>{" "}
          <span className="text-[#e32f77]">EDITING</span>
        </h2>
        <p className="z-10 font-nunito text-[16px] lg:text-xl leading-8 lg:leading-[28px] text-right lg:w-2/4 text-[#e32f77]  mt-4">
          Let Your Imagination Run Wild with Our Editing Expertise Where
          Boundaries Fade and Your Vision Takes Center Stage, Leaving a Lasting
          Impact on the World.
        </p>

        <div className="z-10">
          <ButtonPink>
            <Link
              to={"contact"}
              spy={true}
              smooth={true}
              offset={0}
              duration={1000}
            >
              Conatct Us
            </Link>
          </ButtonPink>
        </div>
      </div>
    </div>
  );
}

export default Banner1;
