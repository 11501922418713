import { Link } from "react-scroll";
import ButtonPink from "../../../core/button-pink";
import "./index.css";
function AboutUs() {
  return (
    <section className=" pt-9 pb-20  " id="about">
      <div className="  bg-[#e32f77] background-back pt-8 lg:pt-[7rem]  ">
        <div className="  overflow-hidden  mx-4 lg:mx-28  lg:pl-[5rem] bg-white h-fit lg:h-[44rem] background-right ">
          <div className="grid grid-cols-3 gap-2 background-img h-[26rem] lg:h-[44rem]">
            <div className="col-span-3 lg:col-span-1 px-4 lg:px-0">
              {" "}
              <h2 className="z-10 relative text-[2.5rem] lg:text-[5rem] xl:text-[6rem]  font-[800] font-lato whitespace-nowrap">
                <span className="outline-font text-white">ABOUT</span>{" "}
                <span className="text-[#e32f77]">US</span>
              </h2>
              <p
                className="z-10 relative font-nunito text-[13px] lg:text-[16px] xl:text-[19px]  text-[#e32f77] mt-4"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                Hey there, welcome to Dev Art! We're not your average company -
                we're a trio of friends with diverse backgrounds and a shared
                passion for creativity. We've pooled our talents to create
                something special. As freelancers turned founders, we're all
                about bringing our unique skills together to craft top-notch
                services that push boundaries and spark inspiration.
              </p>
              <p
                className="z-10 relative font-nunito text-[13px] lg:text-[16px] xl:text-[19px] text-[#e32f77] mt-2"
                data-aos="fade-up"
                data-aos-duration="4500"
              >
                So whether you're in need of web wizardry, video magic, or 3D
                awesomeness, you've come to the right place. Let's make some art
                together.
              </p>
              <div className="z-10 relative">
                <Link
                  activeClass="activeLink"
                  to={"contact"}
                  spy={true}
                  smooth={true}
                  offset={-82}
                  duration={1000}
                >
                  <ButtonPink>Give us a try</ButtonPink>
                </Link>
              </div>
            </div>
            <div className="lg:col-span-2 flex justify-end"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
