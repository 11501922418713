import { useEffect, useMemo, useState, useContext } from "react";
import Banner1 from "./banner/banner-comp-1";
import Banner2 from "./banner/banner-comp-2";
import Banner3 from "./banner/banner-comp-3";
import OurServicesComponent from "./our-services";
import ContactUs from "./contact-us";
import AboutUs from "./about-us";
import Teams from "./teams";
import NavbarContext from "../../context/navbar-context";

function Homepage() {
  const [sec, setSec] = useState(0);

  const { setbg } = useContext(NavbarContext);

  const handleSlide = (s: number) => {
    setSec(s);
  };

  const cls = useMemo(
    () =>
      `-translate-y-${sec}/3  duration-[2000ms] transition-all ease-in-out `,
    [sec]
  );
  useEffect(() => {
    setbg(sec);
  }, [sec]);

  useEffect(() => {
    const timer = setInterval(() => {
      setSec((sec + 1) % 3);
    }, 10000);
    return () => clearInterval(timer);
  });

  return (
    <section className=" ban" id="home">
      <div className="bg-[#ffffff]">
        <div className="absolute z-10 bottom-[36px] right-[1rem] lg:right-20">
          <p
            onClick={() => handleSlide(0)}
            className="text-black font-bold italic text-[13px] lg:text-[18px] leading-6 lg:leading-8 text-right cursor-pointer"
          >
            {sec === 0 ? (
              <>
                Video Editing <span>&mdash;</span>
              </>
            ) : (
              ""
            )}{" "}
            01
          </p>
          <p
            onClick={() => handleSlide(1)}
            className="text-black font-bold italic text-[13px] lg:text-[18px] leading-6 lg:leading-8 text-right cursor-pointer"
          >
            {sec === 1 ? (
              <>
                Web Development <span>&mdash;</span>
              </>
            ) : (
              ""
            )}{" "}
            02
          </p>
          <p
            onClick={() => handleSlide(2)}
            className="text-black font-bold italic text-[13px] lg:text-[18px] leading-6 lg:leading-8 text-right cursor-pointer"
          >
            {sec === 2 ? (
              <>
                3D Visualisation <span>&mdash;</span>
              </>
            ) : (
              ""
            )}{" "}
            03
          </p>
        </div>
        <div className="h-screen w-full overflow-hidden">
          <div className={cls}>
            <Banner1 />
            <Banner2 />
            <Banner3 />
          </div>
        </div>

        <OurServicesComponent />
        <AboutUs />
        <ContactUs />
        <Teams />
      </div>
    </section>
  );
}

export default Homepage;
