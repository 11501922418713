import arvind from "../../../assets/team/team-arvind.jpg";
export const teamData = [
  {
    img: "/images/teams/team-mukul.jpg",
    title: "Shubham",
    desc: "3D Visuliser",
    delay: 6500,
  },
  {
    img: "/images/teams/team-vishal.jpg",
    title: "Vishal kumar",
    desc: "Web App Developer",
    delay: 4500,
  },
  {
    img: arvind,
    title: "Aravind",
    desc: "Video Editor",
    delay: 3000,
  },
];
