export const servicesData = [
  {
    icon: "/images/service/video-edit.svg",
    title: { first: "Video", last: "Editing" },
    desc: "At DevArt, we specialize in professional video editing services that transform raw footage into captivating visual stories. Our team of skilled editors ensures high-quality, creative, and timely delivery of edited videos that fit your budget and vision.Services include footage enhancement, storyboarding, motion graphics, and format optimization. Let us bring your vision to life through the power of video editing. Contact us today to get started.",
    delay: 3000,
  },
  {
    icon: "/images/service/Web.svg",
    title: { first: "Web App ", last: "Development" },
    desc: "Our approach to web application development focuses on understanding  business goals and translating them into robust, scalable solutions. We use the latest technologies and frameworks to ensure  your web application not only meets but exceeds industry standards. We value customer satisfaction and will work closely with you at every stage of the development process to ensure your vision is realized. Our experienced team ensures maximum quality and customer satisfaction in every phase. We move your company forward in the digital landscape.Contact us to get started today.",
    delay: 4500,
  },
  {
    icon: "/images/service/mobile-app.svg",
    title: { first: "Mobile App", last: "Development" },
    desc: "We understand that every business is unique. That's why we take a personalized approach to developing custom mobile applications that align perfectly with your brand identity and goals. Our first concern is quality. Our extensive testing procedures guarantee that your mobile application operates flawlessly on a variety of platforms and devices, offering users a smooth and error-free experience.  We are experts at developing the most advanced mobile applications that precisely match your specific company goals and demands.",
    delay: 6500,
  },
  {
    icon: "/images/service/3D.svg",
    title: { first: "3D ", last: "Visualisation" },
    desc: "Customised apps are accurately aligned with your business and security needs. It adapts to your business environment and delivers the desired result within the stipulated timeframe. With custom treatment, we make sure to highlight all unique aspects of your business. Simultaneously, this service offers you to have a flexible budget too.",
    delay: 8500,
  },
];
