import { Squash as Hamburger } from "hamburger-react";
import { useEffect, useState, useContext } from "react";
import NavLinkComp from "./Navlink-componet";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import NavbarContext from "../../context/navbar-context";
import logoWhite from "../../assets/navbar/Dev-Art-Logo-white.png";

function Navbar() {
  const [isOpen, setOpen] = useState(false);
  const [duration, setDuration] = useState(1);
  const [navbar, setNavbar] = useState(false);
  const [isMobile] = useState(() => window.innerWidth < 700);
  const [size, setSize] = useState(30);
  const [bgColor, setBgColor] = useState("#e32f77");
  const [logo, setLogo] = useState("/images/logo/Dev-Art-Logo-pink.svg");

  const { bg } = useContext(NavbarContext);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (bg === 0) {
      setBgColor("#e32f77");
      setLogo("/images/logo/Dev-Art-Logo-pink.svg");
    } else if (bg === 1) {
      setBgColor("#73ced8");
      setLogo("/images/logo/Dev-Art-Logo-blue.svg");
    } else if (bg === 2) {
      setBgColor("#93478f");
      setLogo("/images/logo/Dev-Art-Logo-purple.svg");
    }
  }, [bg]);

  useEffect(() => {
    if (location.pathname !== "/") {
      setBgColor("#e32f77");
      setLogo("/images/logo/Dev-Art-Logo-pink.svg");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isMobile) {
      setSize(20);
    }
  }, [isMobile]);

  useEffect(() => {
    if (!isOpen) {
      setDuration(1);
    } else {
      setDuration(1.5);
    }
  }, [isOpen]);

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);
  return (
    <div className={navbar ? " active" : "navbackground"}>
      <div className="w-full py-1 px-5 lg:py-3 lg:px-24   ">
        <div className="flex items-center justify-between   ">
          <img
            onClick={() => navigate("/")}
            src={navbar ? logoWhite : logo}
            className=" cursor-pointer w-[5rem] lg:w-[10rem]"
          />
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            size={size}
            duration={duration}
            color={navbar ? "white" : bgColor}
            easing="ease-in"
            rounded
          />
          <NavLinkComp isOpen={isOpen} setOpen={setOpen} size={size} />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
