import { useEffect, useState } from "react";

type ServiceTextProps = {
  service: {
    icon: string;
    title: { first: string; last: string };
    desc: string;
    delay: number;
  };
  index: number;
};

const ServiceText = ({ service, index }: ServiceTextProps) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <div key={index} className="  p-4 h-fit  border-[#070707]   ">
      <div className="flex items-center md:block">
        <img className="w-10 md:w-[4rem] h-[4rem]" src={service.icon} />
        <h5 className=" mt-0 lg:mt-5 ml-3 lg:ml-0 font-montserrat font-bold text-[1.15rem] md:text-2xl  mb-0 lg:mb-3">
          {service.title.first}
          <br className="hidden lg:block" />
          {service.title.last}
        </h5>
      </div>
      <div className={`long-text ${collapse && "expanded"}`}>
        <p
          className={`pl-0 leading-5 text-[13px] lg:text-[16px] lg:leading-[28px] font-nunito md:pl-0 ${
            !collapse && "ellipsis"
          }`}
        >
          {service.desc}
        </p>
        <p
          onClick={() => setCollapse((prev) => !prev)}
          className="text-[#70C1CC] text-[14px] lg:text-[16px] hover:text-[#03a2b7] cursor-pointer "
        >
          {collapse ? "read less" : "read more"}
        </p>
      </div>
    </div>
  );
};

export default ServiceText;
