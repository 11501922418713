import { useNavigate } from "react-router-dom";
import ButtonBlue from "../../../core/button-blue";
import "../homepage.css";
import { Link } from "react-scroll";

function Banner2() {
  const navigate = useNavigate();
  return (
    <div className="banner2-background relative flex justify-left items-center pl-4  md:pl-24">
      <div className="absolute bg-white lg:bg-transparent h-screen w-screen opacity-80 lg:opacity-100"></div>{" "}
      <div className="content ">
        <img
          className="absolute top-0 left-0 rotate-[270deg] w-[8rem]"
          src="/images/banner/DotsBlue.svg"
        />
        <h2 className="z-10 relative text-[1.85rem] lg:text-[4rem]   font-[800] font-lato whitespace-nowrap">
          <span className="outline-font-blue text-white">WEB</span>{" "}
          <span className="text-[#73ced8]">DEVELOPEMENT</span>
        </h2>
        <p className="z-10 relative font-nunito text-[16px] lg:text-xl leading-6 lg:leading-[28px] w-3/4 text-[#73ced8]  mt-4">
          Your website, your story – let's make it unforgettable!
        </p>
        <div className="z-10 relative">
          <ButtonBlue check={false}>
            <Link
              to={"contact"}
              spy={true}
              smooth={true}
              offset={0}
              duration={1000}
            >
              Conatct Us
            </Link>
          </ButtonBlue>
        </div>
      </div>
    </div>
  );
}

export default Banner2;
