import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import toast from "react-hot-toast";
import axios from "axios";
import { FaTimesCircle } from "react-icons/fa";
import "./index.css";
import ButtonBlue from "../../../core/button-blue";

type FormValues = {
  name: string;
  email: string;
  message: string;
  subject: string;
  phone: string;
};

function ContactUs() {
  const [isLoading, setLoading] = useState(false);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      name: "",
      email: "",
      message: "",
      subject: "",
      phone: "",
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    console.log({ data });
    try {
      setLoading(true);
      const { data: res } = await axios.post(
        "http://51.20.147.172:5000/contactus",
        data
      );
      if (res.status) {
        reset();
        setLoading(false);
        toast.success("Your message has sent to us");
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
  };
  const [isMobile] = useState(() => window.innerWidth < 700);
  const [animationType, setAnimationType] = useState("fade-left");
  useEffect(() => {
    if (isMobile) {
      setAnimationType("zoom-out");
    }
  }, [isMobile]);
  return (
    <section
      className="pl-4 pr-4 md:pl-24 bg-[#daf2f8] lg:pr-20 xl:pr-24 pt-9 pb-20"
      id="contact"
    >
      <div className="w-full flex justify-end">
        <div className=" lg:w-9/12 xl:w-5/6 mt-10 bg-white shadow-[0px_0px_9px_3px_#346e74b3] ">
          <div className="grid  lg:grid-cols-6 sm:grid-cols-1 gap-8 p-4 lg:p-10">
            <div className=" relative hidden lg:block col-span-1">
              <div className="absolute w-max lg:w-[32rem]">
                <img
                  data-aos="fade-right"
                  data-aos-duration="4500"
                  className="lg:absolute lg:h-[37rem]  lg:left-[-20rem] xl:left-[-18rem] shadow-[0px_0px_9px_3px_#346e74b3]  background"
                  src="/images/contactus/ContactUsVector.png"
                  alt=""
                />
              </div>
            </div>
            <div className="relative col-span-5  lg:pl-[5.55rem]">
              <h2 className="text-[2.5rem] lg:text-[5rem] xl:text-[6rem]  font-[800] font-lato whitespace-nowrap">
                <span className="outline-font-blue text-white">CONTACT</span>{" "}
                <span className="text-[#73ced8]">US</span>
              </h2>
              <p
                data-aos="fade-up"
                data-aos-duration="3000"
                className="font-nunito text-[13px] lg:text-[16px] xl:text-[19px] leading-5 lg:leading-[28px] text-[#73ced8] mt-4"
              >
                Got ideas brewing or questions popping?
              </p>
              <p
                data-aos="fade-up"
                data-aos-duration="4500"
                className="font-nunito text-[13px] lg:text-[16px] xl:text-[19px] leading-5 lg:leading-[28px] text-[#73ced8] mt-2 lg:mt-4"
              >
                Let's chat! Whether you're dreaming up a snazzy website, cooking
                up a killer video, or envisioning mind-blowing 3D visuals, we're
                all ears! Drop us a line and let's bring your creative visions
                to life together!
              </p>
              <form
                data-aos={animationType}
                data-aos-duration="4000"
                className="mt-4 lg:mt-10"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Name*"
                        className={`${
                          errors.name ? "border-rose-500" : "border-primary"
                        } h-12  p-5 focus-visible:outline-none border-b-2 w-full bg-slate-100 rounded-xl`}
                        {...register("name", { required: true })}
                      />
                      <FaTimesCircle
                        className={`${
                          !errors.name ? "hidden" : ""
                        } absolute right-[9px] top-[14px] text-rose-500`}
                      />
                    </div>
                    <div className="relative ">
                      <input
                        type="email"
                        placeholder="Email Address*"
                        className={`${
                          errors.email ? "border-rose-500" : "border-primary"
                        } h-12  p-5 focus-visible:outline-none border-b-2 w-full bg-slate-100 rounded-xl`}
                        {...register("email", {
                          required: true,
                          pattern:
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                        })}
                      />
                      <FaTimesCircle
                        className={`${
                          !errors.email ? "hidden" : ""
                        } absolute right-[9px] top-[14px] text-rose-500`}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Phone Number*"
                        className={`${
                          errors.phone ? "border-rose-500" : "border-primary"
                        } h-12  p-5 focus-visible:outline-none border-b-2 w-full bg-slate-100 rounded-xl`}
                        {...register("phone", {
                          required: true,
                          // pattern: /(7|8|9|6)\d{9}/,
                          // maxLength: 10,
                        })}
                      />
                      <FaTimesCircle
                        className={`${
                          !errors.phone ? "hidden" : ""
                        } absolute right-[9px] top-[14px] text-rose-500`}
                      />
                    </div>

                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Subject"
                        className={`${
                          errors.phone ? "border-rose-500" : "border-primary"
                        } h-12  p-5 focus-visible:outline-none border-b-2 w-full bg-slate-100 rounded-xl`}
                        {...register("subject", { required: true })}
                      />
                      <FaTimesCircle
                        className={`${
                          !errors.subject ? "hidden" : ""
                        } absolute right-[9px] top-[14px] text-rose-500`}
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <textarea
                      placeholder="Message body"
                      className={`${
                        errors.message ? "border-rose-500" : "border-primary"
                      } w-full bg-slate-100 rounded-xl p-5 focus-visible:outline-none border-b-2 resize-none`}
                      {...register("message", { required: true })}
                    />
                    <FaTimesCircle
                      className={`${
                        !errors.message ? "hidden" : ""
                      } absolute right-[9px] top-[14px] text-rose-500`}
                    />
                  </div>
                  <div className="text-right relative">
                    <button
                      data-aos="flip-up"
                      disabled={isLoading}
                      type="submit"
                      className={`px-2 pt-2 pb-2 md:px-7 md:py-3 mt-6 lg:mt-10 text-[11px] md:text-[15px] rounded font-bold text-[#73ced8] border border-[#73ced8]  focus:outline-none transition-all duration-500 hover:text-white hover:shadow-lg hover:shadow-[#73ced8] hover:bg-[#73ced8]`}
                    >
                      {isLoading ? "SENDING EMAIL " : "SUBMIT NOW"}
                    </button>
                  </div>

                  {/* <ButtonBlue>
                    Submit Now
                  </ButtonBlue> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
