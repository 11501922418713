type SpecialtiesProps = {
  para: string;
  specialties: { icon: React.ReactNode; text: string }[];
};

function Specialties({ para, specialties }: SpecialtiesProps) {
  return (
    <div className="pl-4 pr-4 md:pl-24 md:pr-24 pt-9 pb-10 lg:pb-20 bg-slate-100">
      <p className="font-nunito  text-center text-[13px] lg:text-[18px] leading-5 lg:leading-[28px] text-[#e32f77] mt-4">
        Dev Art
      </p>
      <h2 className="text-[1.5rem] lg:text-[3rem] text-center  font-[800] font-lato whitespace-nowrap">
        <span className="outline-font-dark text-white">MY</span>
        <span className="text-[#e32f77]">SPECIALTIES</span>
      </h2>
      <p className="font-nunito font-bold text-center text-[13px] lg:text-xl leading-5 lg:leading-[28px] text-[#e32f77] mt-4">
        {para}
      </p>
      <div className="flex justify-around mt-8">
        {specialties.map(({ text, icon }) => (
          <div className="flex flex-col items-center">
            {icon}
            <p className="font-nunito font-bold text-center text-[13px] lg:text-[18px] leading-5 lg:leading-[28px] text-[#e32f77] mt-4">
              {text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Specialties;
