// import "./App.css";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import Homepage from "./components/homepage";
import Layout from "./layout";
import VideoEditor from "./components/video-editor";
import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
import { useEffect, useState } from "react";
import WebDev from "./components/web-dev";
import NavbarContextProvider from "./context/navbar-context-provide";
import Visulization from "./components/3Dvisulization";
import NotFound from "./components/not-found";
import { Toaster } from "react-hot-toast";
import Aos from "aos";
import "aos/dist/aos.css";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="" element={<Homepage />} />
      <Route path="video" element={<VideoEditor />} />
      <Route path="web-dev" element={<WebDev />} />
      <Route path="3D" element={<Visulization />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

function App() {
  const lenis = useLenis(({ scroll }) => {
    // called every scroll
  });

  const [isEnable, setEnable] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    if (scrollPosition > 400) {
      setEnable(true);
    } else {
      setEnable(false);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="App">
      <ReactLenis root>
        <NavbarContextProvider>
          {" "}
          <RouterProvider router={router} />
        </NavbarContextProvider>
      </ReactLenis>
      <div className="fixed flex flex-col bottom-[1rem] lg:bottom-[2rem] right-[10px]">
        <button
          onClick={() => {
            window.open("https://wa.me/7835993022", "_blank", "noreferrer");
          }}
          className="bg-transparent   rounded-xl  p-1 z-10"
        >
          <img src={"/images/whatsapp.png"} className=" w-10 lg:w-14" />
        </button>
        <button
          onClick={() => {
            window.open("https://t.me/+9650694006", "_blank", "noreferrer");
          }}
          className="bg-transparent   rounded-xl  p-1 z-10 mb-1 "
        >
          <img src={"/images/telegram.png"} className="w-10 lg:w-14" />
        </button>

        {isEnable && (
          <button
            className="bg-white   rounded-xl  p-1 z-10"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          >
            <img src={"/images/rocket.gif"} className="w-10 lg:w-14" />
          </button>
        )}
      </div>

      <Toaster position="top-right" />
    </div>
  );
}

export default App;
