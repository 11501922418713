import { FaReact, FaNode } from "react-icons/fa";
import { IoColorPaletteSharp } from "react-icons/io5";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { MdOutlineMonochromePhotos } from "react-icons/md";
import { FaVideo } from "react-icons/fa";
import { Gi3DGlasses } from "react-icons/gi";
import { SlSocialInstagram } from "react-icons/sl";

export const skills = [
  { name: "Adobe Premiere Pro", progress: 95 },
  { name: "Adobe After Effects", progress: 75 },
  { name: "Adobe Photoshop", progress: 90 },
  { name: "Canva", progress: 80 },
  { name: "Ligthroom", progress: 60 },
];

export const specialties = [
  {
    icon: (
      <MdOutlineSlowMotionVideo className="h-10 w-10 lg:h-16 lg:w-16 text-[#e32f77]" />
    ),
    text: "Video Editing",
  },
  {
    icon: (
      <IoColorPaletteSharp className="h-10 w-10 lg:h-16 lg:w-16 text-[#e32f77]" />
    ),
    text: " Color Grading",
  },
];

export const education = [
  {
    time: "2016-2020",
    desg: "Btech(EEE) - MRI University",
    info: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean convallis, risus quis feugiat dapibus.",
  },
];

export const workExp = [
  {
    time: "2021-2022",
    desg: "Video Editor - Editoo Media",
    info: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean convallis, risus quis feugiat dapibus.",
  },
  {
    time: "2022-present",
    desg: "Video Editor - Freelancer",
    info: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean convallis, risus quis feugiat dapibus.",
  },
];

export const intrest = [
  {
    name: "Photography",
    info: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aeneanconvallis, risus quis feugiat dapibus.",
    icon: (
      <MdOutlineMonochromePhotos className="h-10 w-10 lg:h-16 lg:w-16 text-[#00c2d7]" />
    ),
  },
  {
    name: "Videography",
    info: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aeneanconvallis, risus quis feugiat dapibus.",
    icon: <FaVideo className="h-10 w-10 lg:h-16 lg:w-16 text-[#00c2d7]" />,
  },
  {
    name: "VFX",
    info: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aeneanconvallis, risus quis feugiat dapibus.",
    icon: <Gi3DGlasses className="h-10 w-10 lg:h-16 lg:w-16 text-[#00c2d7]" />,
  },
  {
    name: "Content Creation",
    info: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aeneanconvallis, risus quis feugiat dapibus.",
    icon: (
      <SlSocialInstagram className="h-10 w-10 lg:h-16 lg:w-16 text-[#00c2d7]" />
    ),
  },
];
