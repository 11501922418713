import { useEffect, useState } from "react";
import ServiceText from "./service-text";
import { servicesData } from "./services-data";

const OurServicesComponent = () => {
  const [isMobile] = useState(() => window.innerWidth < 700);
  const [animationType, setAnimationType] = useState("fade-left");
  useEffect(() => {
    if (isMobile) {
      setAnimationType("zoom-out");
    }
  }, [isMobile]);
  return (
    <section className="pl-4 pr-4 md:pl-24	md:pr-24 pt-9 pb-20" id="services">
      <h2 className="text-[2.5rem] lg:text-[6rem]  font-[800] font-lato whitespace-nowrap">
        <span className="outline-font-blue text-white">OUR</span>{" "}
        <span className="text-[#73ced8]">SERVICES</span>
      </h2>
      <p className="lg:w-3/4 my-3 lg:my-0 text-[#73ced8] text-[13px] lg:text-[16px] leading-5 lg:leading-[28px] font-nunito">
        We always look forward to work with clients who let us experiment to
        their best potential. We believe in welcoming futuristic thoughts, new
        ideas & non-stop innovation to the table. No matter what your business
        demands, our expertise is gathering the best resources to offer on-time
        solutions.
      </p>

      <div className="columns-1 md:columns-2 xl:columns-4 md:mt-8 lg:grid lg:grid-cols-4 lg:gap-4">
        {servicesData.map((service, index) => {
          return (
            <div
              className=" md:px-4 py-4 "
              data-aos={animationType}
              data-aos-duration={service.delay}
            >
              <ServiceText service={service} index={index} />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default OurServicesComponent;
