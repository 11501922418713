function Work() {
  return (
    <div className="pl-4 pr-4 md:pl-24 md:pr-24 pt-9 " id="myWork">
      <p className="font-nunito  text-center text-[13px] lg:text-[18px] leading-5 lg:leading-[28px] text-[#00c2d7] mt-4">
        Welcome to Dev Art
      </p>
      <h2 className="text-[1.5rem] lg:text-[3rem] text-center  font-[800] font-lato whitespace-nowrap">
        <span className="outline-font-dark-blue text-white">MY</span>
        <span className="text-[#00c2d7]">WORK</span>
      </h2>
      <p className="font-nunito font-bold text-center text-[13px] lg:text-xl leading-5 lg:leading-[28px] text-[#00c2d7] mt-4">
        Explore my portfolio to witness the fusion of creativity and
        craftsmanship in each project I've brought to life.
      </p>
    </div>
  );
}

export default Work;
