"use client";
import React, { useState, useRef, useEffect } from "react";
import { LayoutGrid } from "../../core/ui/layout-grid";

export default function LayoutGridDemo() {
  return (
    <div className="h-fit py-10 w-full relative">
      <LayoutGrid cards={cards} />
    </div>
  );
}

const SkeletonOne = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">DevArt</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        Developed a new service based platform from scratch, implimeted most of
        things by basic css and javascript ,less use of Npm. It is developved in
        typescript and had a feature of contact via email for the service
        provider.
      </p>
      <button
        type="submit"
        className={`px-2 pt-2 pb-2 md:px-5 md:py-2 text-[11px] md:text-[15px] rounded font-bold text-[#d4d9da] border border-[#d4d9da]  focus:outline-none transition-all duration-500 ease-in-out hover:text-[#ffffff] hover:shadow-lg hover:shadow-[#ffffff] hover:border-[#ffffff]`}
      >
        <a href="https://www.devarts.tech/" target="_blank">
          {" "}
          Take me there
        </a>
      </button>
    </div>
  );
};

const SkeletonTwo = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">Project Buddy</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        It is a opean source project created by Amir sohel and me, where you can
        post your projects to get help, search anyones github profile.
      </p>
      <button
        type="submit"
        className={`px-2 pt-2 pb-2 md:px-5 md:py-2 text-[11px] md:text-[15px] rounded font-bold text-[#d4d9da] border border-[#d4d9da]  focus:outline-none transition-all duration-500 ease-in-out hover:text-[#ffffff] hover:shadow-lg hover:shadow-[#ffffff] hover:border-[#ffffff]`}
      >
        <a href="https://projectbuddy.tech/" target="_blank">
          {" "}
          Take me there
        </a>
      </button>
    </div>
  );
};
const SkeletonThree = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">StenBuzz</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        A service basied company for all web and mobile related service.
      </p>
      <button
        type="submit"
        className={`px-2 pt-2 pb-2 md:px-5 md:py-2 text-[11px] md:text-[15px] rounded font-bold text-[#d4d9da] border border-[#d4d9da]  focus:outline-none transition-all duration-500 ease-in-out hover:text-[#ffffff] hover:shadow-lg hover:shadow-[#ffffff] hover:border-[#ffffff]`}
      >
        <a href="https://www.stenbuzz.com/" target="_blank">
          {" "}
          Take me there
        </a>
      </button>
    </div>
  );
};
const SkeletonFour = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">Data Plant</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        Clients project, worked only on UI
      </p>
      <button
        type="submit"
        className={`px-2 pt-2 pb-2 md:px-5 md:py-2 text-[11px] md:text-[15px] rounded font-bold text-[#d4d9da] border border-[#d4d9da]  focus:outline-none transition-all duration-500 ease-in-out hover:text-[#ffffff] hover:shadow-lg hover:shadow-[#ffffff] hover:border-[#ffffff]`}
      >
        <a href="https://www.thedataplant.com/" target="_blank">
          {" "}
          Take me there
        </a>
      </button>
    </div>
  );
};
const SkeletonFive = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">Tuhu</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        Clients project, reworked the whole UI for the whole website.
      </p>
      <button
        type="submit"
        className={`px-2 pt-2 pb-2 md:px-5 md:py-2 text-[11px] md:text-[15px] rounded font-bold text-[#d4d9da] border border-[#d4d9da]  focus:outline-none transition-all duration-500 ease-in-out hover:text-[#ffffff] hover:shadow-lg hover:shadow-[#ffffff] hover:border-[#ffffff]`}
      >
        <a href="https://tuhulocal.com/" target="_blank">
          {" "}
          Take me there
        </a>
      </button>
    </div>
  );
};
const SkeletonSix = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">HSBC</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        Pure HTML ans SASS webpage for HSBC.
      </p>
      <button
        type="submit"
        className={`px-2 pt-2 pb-2 md:px-5 md:py-2 text-[11px] md:text-[15px] rounded font-bold text-[#d4d9da] border border-[#d4d9da]  focus:outline-none transition-all duration-500 ease-in-out hover:text-[#ffffff] hover:shadow-lg hover:shadow-[#ffffff] hover:border-[#ffffff]`}
      >
        <a href="https://hsbc.vercel.app/" target="_blank">
          {" "}
          Take me there
        </a>
      </button>
    </div>
  );
};
const SkeletonSeven = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">Ecommerce</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        Developed a new e-commerce platform from scratch, Integrated various API
        services into the website to monitor and administer orders, communicate
        with customers, and ensure secure payments.Implemented Nodemailer for
        Secure Password Management and Order Confirmation.
      </p>
      <button
        type="submit"
        className={`px-2 pt-2 pb-2 md:px-5 md:py-2 text-[11px] md:text-[15px] rounded font-bold text-[#d4d9da] border border-[#d4d9da]  focus:outline-none transition-all duration-500 ease-in-out hover:text-[#ffffff] hover:shadow-lg hover:shadow-[#ffffff] hover:border-[#ffffff]`}
      >
        <a href="http://51.20.147.172:4000/" target="_blank">
          {" "}
          Take me there
        </a>
      </button>
    </div>
  );
};
const SkeletonEight = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">Jwala Tour & Travel</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        A travell website for Clients.
      </p>
      <button
        type="submit"
        className={`px-2 pt-2 pb-2 md:px-5 md:py-2 text-[11px] md:text-[15px] rounded font-bold text-[#d4d9da] border border-[#d4d9da]  focus:outline-none transition-all duration-500 ease-in-out hover:text-[#ffffff] hover:shadow-lg hover:shadow-[#ffffff] hover:border-[#ffffff]`}
      >
        <a href="https://jwala-5fwy.vercel.app/" target="_blank">
          {" "}
          Take me there
        </a>
      </button>
    </div>
  );
};
const SkeletonNine = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">Entertainment Hub</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        Here you can search the movies and get info about it Including trailer.
      </p>
      <button
        type="submit"
        className={`px-2 pt-2 pb-2 md:px-5 md:py-2 text-[11px] md:text-[15px] rounded font-bold text-[#d4d9da] border border-[#d4d9da]  focus:outline-none transition-all duration-500 ease-in-out hover:text-[#ffffff] hover:shadow-lg hover:shadow-[#ffffff] hover:border-[#ffffff]`}
      >
        <a href="https://entertainment-hub-two.vercel.app/" target="_blank">
          {" "}
          Take me there
        </a>
      </button>
    </div>
  );
};
const SkeletonTen = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">Crypto Tracker</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        Here you can tact the crypto currency.
      </p>
      <button
        type="submit"
        className={`px-2 pt-2 pb-2 md:px-5 md:py-2 text-[11px] md:text-[15px] rounded font-bold text-[#d4d9da] border border-[#d4d9da]  focus:outline-none transition-all duration-500 ease-in-out hover:text-[#ffffff] hover:shadow-lg hover:shadow-[#ffffff] hover:border-[#ffffff]`}
      >
        <a href="https://crypto-tracker-beta-two.vercel.app/" target="_blank">
          {" "}
          Take me there
        </a>
      </button>
    </div>
  );
};
const SkeletonEleven = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">Merge Call</p>
      <p className="font-normal text-base text-white">
        Serving as a centralized hub for all telephonic activities, leveraging
        Twilio for telephony features,orchestrated payment integration using
        Stripe, streamlining payment processing,Utilized Socket.io and Redux for
        real-time data synchronization and state management.
      </p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200"></p>
      <button
        type="submit"
        className={`px-2 pt-2 pb-2 md:px-5 md:py-2 text-[11px] md:text-[15px] rounded font-bold text-[#d4d9da] border border-[#d4d9da]  focus:outline-none transition-all duration-500 ease-in-out hover:text-[#ffffff] hover:shadow-lg hover:shadow-[#ffffff] hover:border-[#ffffff]`}
      >
        <a href="https://mergecall.com/" target="_blank">
          {" "}
          Take me there
        </a>
      </button>
    </div>
  );
};
const SkeletonTwelve = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">The weather forcast</p>
      <p className="font-normal text-base text-white">
        Experience real-time weather updates at your fingertips with our React
        app. Stay informed about the forecast in your area and plan your day
        accordingly. With intuitive design and accurate data, it's your go-to
        companion for all things weather-related. Never get caught unprepared
        again!
      </p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200"></p>
      <button
        type="submit"
        className={`px-2 pt-2 pb-2 md:px-5 md:py-2 text-[11px] md:text-[15px] rounded font-bold text-[#d4d9da] border border-[#d4d9da]  focus:outline-none transition-all duration-500 ease-in-out hover:text-[#ffffff] hover:shadow-lg hover:shadow-[#ffffff] hover:border-[#ffffff]`}
      >
        <a href="https://weather-forecasting-sooty.vercel.app/" target="_blank">
          {" "}
          Take me there
        </a>
      </button>
    </div>
  );
};

const cards = [
  {
    id: 1,
    content: <SkeletonEleven />,
    className: "md:col-span-2 h-[2rem] md:h-[8rem] cursor-pointer",
    thumbnail: "/images/my-projects/mergecall.png",
  },
  {
    id: 2,
    content: <SkeletonOne />,
    className: "md:col-span-1 h-[2rem] md:h-[8rem] cursor-pointer",
    thumbnail: "/images/my-projects/devart.png",
  },
  {
    id: 3,
    content: <SkeletonTwo />,
    className: "col-span-1 h-[2rem] md:h-[8rem] cursor-pointer",
    thumbnail: "/images/my-projects/projectbuddy.png",
  },
  {
    id: 4,
    content: <SkeletonThree />,
    className: "col-span-2 h-[2rem] md:h-[8rem] cursor-pointer",
    thumbnail: "/images/my-projects/stenbuzz.png",
  },
  {
    id: 5,
    content: <SkeletonFour />,
    className: "md:col-span-2 h-[2rem] md:h-[8rem] cursor-pointer",
    thumbnail: "/images/my-projects/thedataplant.png",
  },
  {
    id: 6,
    content: <SkeletonFive />,
    className: "md:col-span-1 h-[2rem] md:h-[8rem] cursor-pointer",
    thumbnail: "/images/my-projects/tuhulocal.png",
  },
  {
    id: 7,
    content: <SkeletonSix />,
    className: "md:col-span-1 h-[2rem] md:h-[8rem] cursor-pointer",
    thumbnail: "/images/my-projects/hsbc.png",
  },
  {
    id: 8,
    content: <SkeletonSeven />,
    className: "md:col-span-2 h-[2rem] md:h-[8rem] cursor-pointer",
    thumbnail: "/images/my-projects/ecomerce.png",
  },
  {
    id: 9,
    content: <SkeletonEight />,
    className: "md:col-span-2 h-[2rem] md:h-[8rem] cursor-pointer",
    thumbnail: "/images/my-projects/jwala.png",
  },
  {
    id: 10,
    content: <SkeletonNine />,
    className: "md:col-span-1 h-[2rem] md:h-[8rem] cursor-pointer",
    thumbnail: "/images/my-projects/entertainment-hub.png",
  },
  {
    id: 11,
    content: <SkeletonTen />,
    className: "md:col-span-1 h-[2rem] md:h-[8rem] cursor-pointer",
    thumbnail: "/images/my-projects/crypto-tracker.png",
  },
  {
    id: 12,
    content: <SkeletonTwelve />,
    className: "md:col-span-2 h-[2rem] md:h-[8rem] cursor-pointer",
    thumbnail: "/images/my-projects/weather-forecastin.png",
  },
];
