type ProgressProps = {
  progressPercentage: number;
  skill: string;
  index: number;
};
const ProgressBar = ({ progressPercentage, skill, index }: ProgressProps) => {
  return (
    <div key={index} className="h-6 lg:h-8 w-full bg-gray-300">
      <div
        style={{ width: `${progressPercentage}%` }}
        className={`h-full flex justify-between items-center bg-[#e32f77]`}
      >
        <p className="text-white text-[14px] lg:text-[17px] font-bold font-nunito pl-3">
          {skill}
        </p>
        <p className="text-white text-[14px] lg:text-[17px] font-bold font-nunito pr-3">
          {progressPercentage}%
        </p>
      </div>
    </div>
  );
};

export default ProgressBar;
