import { useNavigate } from "react-router-dom";
import ButtonPink from "../../core/button-pink";

function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="h-screen w-screen bg-gray-100 flex items-center">
      <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-[#e32f77]">
        <div className="max-w-md">
          <div className="text-5xl font-dark font-bold mb-8">404</div>
          <p className="text-2xl md:text-3xl font-light leading-normal mb-4">
            Sorry we couldn't find this page.{" "}
          </p>
          <p className="">
            But dont worry, you can find plenty of other things on our homepage.
          </p>
          <ButtonPink onClick={() => navigate("/")}>
            {" "}
            Back to homepage
          </ButtonPink>
        </div>
        <div className="max-w-lg">
          <img src="/images/catsvg.svg" />
        </div>
      </div>
    </div>
  );
}

export default NotFound;
