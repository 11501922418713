import { useEffect, useState } from "react";

type TeamProps = {
  team: { img: string; title: string; desc: string; delay: number };
  index: number;
};
function Team({ team, index }: TeamProps) {
  const [isMobile] = useState(() => window.innerWidth < 700);
  const [cls, setCls] = useState(
    "absolute w-full h-full top-0 bg-[#00000075] translate-y-[31rem] transition-all duration-500 ease-in-out  hover:translate-y-0 rounded-xl flex justify-center items-center "
  );
  const onHover = () => {
    setCls(
      "absolute w-full h-full top-0 bg-[#00000075] translate-y-0 transition-all duration-500 ease-in-out  hover:translate-y-0 rounded-xl flex justify-center items-center "
    );
  };
  const onOut = () => {
    setCls(
      "absolute w-full h-full top-0 bg-[#00000075] translate-y-[31rem] transition-all duration-500 ease-in-out  hover:translate-y-0 rounded-xl flex justify-center items-center "
    );
  };

  useEffect(() => {
    if (isMobile) {
      onHover();
    }
  }, [isMobile]);
  return (
    <div
      key={index}
      className="relative  w-[15rem] h-[15rem] lg:h-[26rem] md:w-[25rem]  shadow-[0px_0px_6px_6px_#d5608f6e;] lg:shadow-[5px_5px_6px_2px_#d5608f6e] rounded-xl overflow-hidden my-8 lg:my-0"
    >
      <img
        src={team?.img}
        className="rounded-xl  object-cover"
        onMouseOver={onHover}
        onMouseOut={onOut}
      />
      <div className={cls}>
        <div className="">
          <p className=" text-center text-4xl font-bold text-white font-nunito">
            {team?.title}
          </p>
          <p className="text-center text-xl text-white font-nunito">
            {team?.desc}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Team;
