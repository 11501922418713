import { Squash as Hamburger } from "hamburger-react";
import { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { Link as LinkTo, useLocation, useNavigate } from "react-router-dom";
import "./navbar.css";
type NavlinkProps = {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  size: number;
};

function NavLinkComp({ isOpen, setOpen, size }: NavlinkProps) {
  const [cls, setCls] = useState(
    "w-full h-screen fixed bg-white z-20 t[#fffffff2]bottom-0 right-0 mt-0 overflow-y-auto py-4 px-5 lg:py-3 lg:px-24 duration-[2000ms] transition-all ease-in-out"
  );
  const [duration, setDuration] = useState(1);

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (link: string) => {
    setOpen(!isOpen);
    navigate(link);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (location.hash) {
        let elem = document.getElementById(location.hash.slice(1));
        if (elem) {
          elem.scrollIntoView({ behavior: "smooth" });
          navigate("/");
        }
      }
    }, 1100);

    if (location.pathname !== "/") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
    return () => clearTimeout(timeout);
  }, [location]);

  useEffect(() => {
    if (!isOpen) {
      setCls(
        "translate-x-full w-full h-screen fixed bg-white lg:bg-[#fffffff2] z-20 top-0 bottom-0 right-0 mt-0 overflow-y-auto py-4 px-5 lg:py-3 lg:px-24 duration-[1000ms] transition-all ease-in"
      );
      setDuration(1);
    } else {
      setCls(
        "w-full h-screen fixed bg-white lg:bg-[#fffffff2] z-20 top-0 bottom-0 right-0 mt-0 overflow-y-auto py-4 px-5 lg:py-3 lg:px-24 duration-[1000ms] lg:duration-[2000ms] transition-all ease-in-out"
      );
      setDuration(1);
    }
  }, [isOpen]);
  return (
    <div className={cls}>
      <div className="flex justify-end items-center">
        <p
          onClick={() => setOpen(!isOpen)}
          className="text-black cursor-pointer under-line-close font-montserrat  text-[15px] leading-[25px] lg:text-[37px] lg:leading-[40px] font-[600] flex  items-center "
        >
          close
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            size={size}
            duration={2}
            color="black"
            easing="ease-in"
            rounded
          />
        </p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-4 mt-10">
        <div className="  flex flex-col justify-between h-[15rem] lg:h-[30rem]">
          {location.pathname !== "/" ? (
            <LinkTo
              to="/#home"
              onClick={() => setOpen(false)}
              className="text-black cursor-pointer under-line font-montserrat  text-[15px] leading-[25px] lg:text-[37px] lg:leading-[40px] font-[900] relative w-fit"
            >
              Home
            </LinkTo>
          ) : (
            <Link
              activeClass="activeLink"
              to={"home"}
              spy={true}
              smooth={true}
              offset={0}
              duration={1000}
              delay={1100}
              onClick={() => setOpen(false)}
              className="text-black cursor-pointer under-line font-montserrat  text-[15px] leading-[25px] lg:text-[37px] lg:leading-[40px] font-[900] relative w-fit"
            >
              Home
            </Link>
          )}
          {location.pathname !== "/" ? (
            <LinkTo
              to="/#services"
              onClick={() => setOpen(false)}
              className="text-black cursor-pointer under-line font-montserrat  text-[15px] leading-[25px] lg:text-[37px] lg:leading-[40px] font-[900] relative w-fit"
            >
              Our Services
            </LinkTo>
          ) : (
            <Link
              activeClass="activeLink"
              to={"services"}
              spy={true}
              smooth={true}
              offset={-82}
              duration={1000}
              delay={1100}
              onClick={() => setOpen(false)}
              className="text-black cursor-pointer under-line font-montserrat  text-[15px] leading-[25px] lg:text-[37px] lg:leading-[40px] font-[900] relative w-fit"
            >
              Our Services
            </Link>
          )}
          {location.pathname !== "/" ? (
            <LinkTo
              to="/#about"
              onClick={() => setOpen(false)}
              className="text-black cursor-pointer under-line font-montserrat  text-[15px] leading-[25px] lg:text-[37px] lg:leading-[40px] font-[900] relative w-fit"
            >
              About Us
            </LinkTo>
          ) : (
            <Link
              activeClass="activeLink"
              to={"about"}
              spy={true}
              smooth={true}
              offset={-82}
              duration={1000}
              delay={1100}
              onClick={() => setOpen(false)}
              className="text-black cursor-pointer under-line font-montserrat  text-[15px] leading-[25px] lg:text-[37px] lg:leading-[40px] font-[900] relative w-fit"
            >
              About us
            </Link>
          )}
          {location.pathname !== "/" ? (
            <LinkTo
              to="/#contact"
              onClick={() => setOpen(false)}
              className="text-black cursor-pointer under-line font-montserrat  text-[15px] leading-[25px] lg:text-[37px] lg:leading-[40px] font-[900] relative w-fit"
            >
              Contact Us
            </LinkTo>
          ) : (
            <Link
              activeClass="activeLink"
              to={"contact"}
              spy={true}
              smooth={true}
              offset={-82}
              duration={1000}
              delay={1100}
              onClick={() => setOpen(false)}
              className="text-black cursor-pointer under-line font-montserrat  text-[15px] leading-[25px] lg:text-[37px] lg:leading-[40px] font-[900] relative w-fit"
            >
              Contact Us
            </Link>
          )}
          {location.pathname !== "/" ? (
            <LinkTo
              to="/#teams"
              onClick={() => setOpen(false)}
              className="text-black cursor-pointer under-line font-montserrat  text-[15px] leading-[25px] lg:text-[37px] lg:leading-[40px] font-[900] relative w-fit"
            >
              Teams
            </LinkTo>
          ) : (
            <Link
              activeClass="activeLink"
              to={"teams"}
              spy={true}
              smooth={true}
              offset={-82}
              duration={1000}
              delay={1100}
              onClick={() => setOpen(false)}
              className="text-black cursor-pointer under-line font-montserrat  text-[15px] leading-[25px] lg:text-[37px] lg:leading-[40px] font-[900] relative w-fit"
            >
              Teams
            </Link>
          )}
        </div>

        <div className=" flex flex-col mt-16 lg:mt-0  justify-between h-fit">
          <p className="text-black cursor-pointer  font-montserrat  text-[15px] leading-[25px] lg:text-[37px] lg:leading-[40px] font-[600] w-fit mb-2 lg:mb-10">
            Services we provide
          </p>
          <p
            onClick={() => handleClick("video")}
            className="text-black cursor-pointer under-line font-montserrat  text-[15px] leading-[25px] lg:text-[25px] lg:leading-[40px] font-[600] mb-2  relative w-fit"
          >
            Video Editing
          </p>
          <p
            onClick={() => handleClick("web-dev")}
            className="text-black cursor-pointer under-line font-montserrat  text-[15px] leading-[25px] lg:text-[25px] lg:leading-[40px] font-[600] mb-2  relative w-fit"
          >
            Web App Development
          </p>

          <p
            onClick={() => handleClick("3D")}
            className="text-black cursor-pointer under-line font-montserrat  text-[15px] leading-[25px] lg:text-[25px] lg:leading-[40px] font-[600] mb-2 relative w-fit"
          >
            3D Visulization
          </p>
        </div>
      </div>
    </div>
  );
}

export default NavLinkComp;
