import ProgressBar from "./progressBar";

type SkillProps = {
  para: string;
  skills: { name: string; progress: number }[];
};

function Skills({ para, skills }: SkillProps) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pl-4 pr-4 md:pl-24	md:pr-24 pt-9 pb-10 lg:pb-20">
      <div className="flex flex-col justify-center">
        <h2 className="text-[1.5rem] lg:text-[3rem]   font-[800] font-lato whitespace-nowrap">
          <span className="outline-font-dark text-white">MY</span>{" "}
          <span className="text-[#e32f77]">SKILLS</span>
        </h2>
        <p className="font-nunito font-bold text-[13px] lg:text-xl leading-5 lg:leading-[28px] text-[#e32f77] mt-4">
          {para}
        </p>
      </div>
      <div>
        {skills.map(({ name, progress }, index) => (
          <div className="my-6">
            <ProgressBar
              progressPercentage={progress}
              skill={name}
              index={index}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Skills;
