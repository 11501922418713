import Education from "../../core/education";
import Intrest from "../../core/intrest";
import Intro from "../../core/intro";
import Skills from "../../core/skills";
import Specialties from "../../core/specialties";
import WorkExperience from "../../core/work-experience/work-experience";
import LayoutGridDemo from "./grid";
// import { AnimatedPinDemo } from "./pin";
import Work from "./work";
import { skills, specialties, education, workExp, intrest } from "./work-data";

function WebDev() {
  return (
    <div>
      <Intro
        img="/images/teams/vishal.jpg"
        heading="Vishal"
        para1={
          "For the past two years, I've been immersed in the world of web development, where creativity meets technical finesse."
        }
        para2={
          " I'm drawn to the dynamic nature of this field, constantly seeking to expand my skills and stay ahead of the curve. With my eyes glued to the screen, I embrace every opportunity to learn and innovate, ensuring I remain at the forefront of the ever-evolving web landscape.          "
        }
        name={"Vishal Kumar Rathore"}
        designation={"Web developer"}
      />
      <Skills
        para={
          "Discover my versatile skill set, honed through hands-on experience and a passion for excellence, ready to tackle any project with precision and creativity."
        }
        skills={skills}
      />
      <Specialties
        para={
          "Dive into my specialized expertise, where I excel in delivering standout results, tailored to elevate your projects with unique flair and professionalism."
        }
        specialties={specialties}
      />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pl-4 pr-4 md:pl-24	md:pr-24 pt-9 pb-20">
        <WorkExperience workExp={workExp} />
        <Education education={education} />
      </div>
      <Intrest
        para={
          "Explore my diverse interests, ranging from exploring new design trends to delving into the latest industry innovations, all fueled by a relentless passion for creative exploration."
        }
        intrest={intrest}
      />
      <Work />
      <LayoutGridDemo />
      {/* <AnimatedPinDemo /> */}
    </div>
  );
}

export default WebDev;
