import { IoFitnessSharp } from "react-icons/io5";
import { GiBattleMech } from "react-icons/gi";

import { Tb3DCubeSphere } from "react-icons/tb";
import { BsBadge3DFill } from "react-icons/bs";
import { LiaLaptopMedicalSolid } from "react-icons/lia";
import { PiGameControllerFill } from "react-icons/pi";

export const skills = [
  { name: "Maya Autodesk", progress: 90 },
  { name: "3ds Max", progress: 60 },
  { name: "Blender 3D", progress: 90 },
  { name: "Adobe Photoshop", progress: 90 },
  { name: "Adobe After Effects", progress: 50 },
  { name: "CorelDraw", progress: 60 },
];

export const specialties = [
  {
    icon: (
      <BsBadge3DFill className="h-10 w-10 lg:h-16 lg:w-16 text-[#e32f77]" />
    ),
    text: "3D Animation",
  },
  {
    icon: (
      <Tb3DCubeSphere className="h-10 w-10 lg:h-16 lg:w-16 text-[#e32f77]" />
    ),
    text: "3D Modeling",
  },
];

export const education = [
  {
    time: "2020-2023",
    desg: "Bachelor's degree in Humanities - Delhi University",
    info: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean convallis, risus quis feugiat dapibus.",
  },
  {
    time: "2023-present",
    desg: "Master's degree in Political Science - Delhi University",
    info: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean convallis, risus quis feugiat dapibus.",
  },
];

export const workExp = [
  {
    time: "December 2023-January 2024",
    desg: "3D Animationist - MAAC India",
    info: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean convallis, risus quis feugiat dapibus.",
  },
];

export const intrest = [
  {
    name: "Game enviroment developing",
    info: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aeneanconvallis, risus quis feugiat dapibus.",
    icon: (
      <PiGameControllerFill className="h-10 w-10 lg:h-16 lg:w-16 text-[#00c2d7]" />
    ),
  },
  {
    name: "Rigging artist",
    info: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aeneanconvallis, risus quis feugiat dapibus.",
    icon: <GiBattleMech className="h-10 w-10 lg:h-16 lg:w-16 text-[#00c2d7]" />,
  },
  {
    name: "Digital artist",
    info: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aeneanconvallis, risus quis feugiat dapibus.",
    icon: (
      <LiaLaptopMedicalSolid className="h-10 w-10 lg:h-16 lg:w-16 text-[#00c2d7]" />
    ),
  },
  {
    name: "Fitness",
    info: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aeneanconvallis, risus quis feugiat dapibus.",
    icon: (
      <IoFitnessSharp className="h-10 w-10 lg:h-16 lg:w-16 text-[#00c2d7]" />
    ),
  },
];
