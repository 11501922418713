import { Link } from "react-scroll";
import ButtonPink from "./button-pink";
type IntroProps = {
  img: string;
  heading: string;
  para1: string;
  para2: string;
  name: string;
  designation: string;
};
function Intro({ img, heading, para1, para2, name, designation }: IntroProps) {
  return (
    <div className="pl-4 pr-4 md:pl-24 md:pr-24 pt-9 pb-8 lg:pb-20">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="flex flex-col justify-center">
          <p className="font-nunito font-bold  text-[13px] lg:text-[18px] leading-5 lg:leading-[28px] text-[#e32f77] mt-4">
            Since 2021
          </p>
          <h2 className="text-[1.5rem] lg:text-[2rem]   font-[800] font-lato whitespace-nowrap">
            <span className="outline-font-dark text-white">Hey there,</span>
            &nbsp;
            <span className="text-[#e32f77]">I'am {heading}</span>
          </h2>
          <p className="font-nunito font-bold  text-[13px] lg:text-[18px] leading-5 lg:leading-[28px] text-[#e32f77] mt-4">
            {para1}
          </p>
          <p className="font-nunito font-bold italic text-[13px] lg:text-[18px] leading-5 lg:leading-[28px] text-[#e32f77] mt-2">
            {para2}
          </p>
          <p className="font-nunito font-bold italic text-[13px] lg:text-[18px] leading-5 lg:leading-[28px] text-[#e32f77] mt-4">
            {name}
          </p>

          <p className="font-nunito font-bold  text-[13px] lg:text-[15px] leading-5 lg:leading-[28px] text-[#e32f77] ">
            {designation}
          </p>
          <div className="-mt-7">
            <ButtonPink>
              <Link
                className="px-2"
                to={"myWork"}
                spy={true}
                smooth={true}
                offset={0}
                duration={1000}
              >
                My Work
              </Link>
            </ButtonPink>
          </div>
        </div>
        <div className="flex justify-center">
          <img
            src={img}
            className="h-[22rem] lg:h-[35rem] w-[35rem] object-contain"
          />
        </div>
      </div>
    </div>
  );
}

export default Intro;
