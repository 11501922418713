type ButtonBlueProps = {
  children: React.ReactNode;
  onClick?: () => void;
  check: boolean;
};
function ButtonBlue({ children, onClick, check = true }: ButtonBlueProps) {
  if (check) {
    return (
      <>
        <button
          onClick={onClick}
          data-aos="flip-up"
          type="submit"
          className={`px-2 pt-2 pb-2 md:px-7 md:py-3 mt-10 text-[11px] md:text-[15px] rounded font-bold text-[#73ced8] border border-[#73ced8]  focus:outline-none transition-all duration-500 ease-in-out hover:text-white hover:shadow-lg hover:shadow-[#73ced8] hover:bg-[#73ced8]`}
        >
          {children}
        </button>
      </>
    );
  }
  return (
    <>
      <button
        type="submit"
        className={`px-2 pt-2 pb-2 md:px-7 md:py-3 mt-10 text-[11px] md:text-[15px] rounded font-bold text-[#73ced8] border border-[#73ced8]  focus:outline-none transition-all duration-500 ease-in-out hover:text-white hover:shadow-lg hover:shadow-[#73ced8] hover:bg-[#73ced8]`}
      >
        {children}
      </button>
    </>
  );
}

export default ButtonBlue;
