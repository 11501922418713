import { useEffect, useState } from "react";
import { AnimatedPinDemo } from "./pin";
import Team from "./team";
import { teamData } from "./team-data";
function Teams() {
  const [isMobile] = useState(() => window.innerWidth < 700);
  const [animationType, setAnimationType] = useState("fade-left");
  useEffect(() => {
    if (isMobile) {
      setAnimationType("zoom-out");
    }
  }, [isMobile]);
  return (
    <section className="pl-4 pr-4 md:pl-24	md:pr-24 pt-9 pb-20 " id="teams">
      <h2 className="text-[2.5rem] lg:text-[5rem] xl:text-[6rem]  text-center font-[800] font-lato whitespace-nowrap">
        <span className="outline-font text-white">OUR</span>{" "}
        <span className="text-[#e32f77]">EXPERTS</span>
      </h2>
      <p
        data-aos="fade-up"
        data-aos-duration="3000"
        className="font-nunito text-center text-[13px] lg:text-[16px] xl:text-[19px] leading-5 lg:leading-[28px] text-[#e32f77] mt-4"
      >
        Meet the dynamic trio behind the scenes at DevArt the Web Wizard, the
        Video Virtuoso, and the 3D Dynamo! With our powers combined, we're here
        to bring your creative visions to life with a sprinkle of magic, a dash
        of flair, and a whole lot of fun. Get ready to be wowed by our team's
        expertise and enthusiasm!
      </p>
      <div className="md:mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-8 px-2 lg:px-8">
        {teamData?.map((i, index) => (
          <div
            data-aos={animationType}
            data-aos-duration={i.delay}
            className="flex justify-center"
          >
            <Team team={i} index={index} />
          </div>
        ))}{" "}
      </div>
    </section>
  );
}

export default Teams;
