import Education from "../../core/education";
import Intrest from "../../core/intrest";
import Intro from "../../core/intro";
import Skills from "../../core/skills";
import Specialties from "../../core/specialties";
import WorkExperience from "../../core/work-experience/work-experience";
import { skills, specialties, education, workExp, intrest } from "./work-data";

function Visulization() {
  return (
    <div>
      <Intro
        img="/images/teams/shubham.jpg"
        heading="Shubham"
        para1={
          "For the past two years, I have been designing and editing videos. I adore creative work because it's amazing."
        }
        para2={
          " I look at my laptop for almost every moment of the day learning new skills. Throughout the day, the creative industry changes, and I would prefer not to get left behind."
        }
        name={"Shubham"}
        designation={"3D Visuliser"}
      />
      <Skills
        para={
          "Discover my versatile skill set, honed through hands-on experience and a passion for excellence, ready to tackle any project with precision and creativity."
        }
        skills={skills}
      />
      <Specialties
        para={
          "Dive into my specialized expertise, where I excel in delivering standout results, tailored to elevate your projects with unique flair and professionalism."
        }
        specialties={specialties}
      />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pl-4 pr-4 md:pl-24	md:pr-24 pt-9 pb-20">
        <WorkExperience workExp={workExp} />
        <Education education={education} />
      </div>
      <Intrest
        para={
          "Explore my diverse interests, ranging from exploring new design trends to delving into the latest industry innovations, all fueled by a relentless passion for creative exploration."
        }
        intrest={intrest}
      />
      <div id="my-work">
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/5ae381175332279.64b17c9a1340b.gif" />
      </div>
    </div>
  );
}

export default Visulization;
