type ButtonPinkProps = {
  children: React.ReactNode;
  onClick?: () => void;
};
function ButtonPink({ children, onClick }: ButtonPinkProps) {
  return (
    <>
      <button
        onClick={onClick}
        data-aos="flip-up"
        type="submit"
        className={`px-2 pt-2 pb-2 md:px-7 md:py-3 mt-10 text-[11px] md:text-[15px] rounded font-bold text-[#e32f77] border border-[#e32f77]  focus:outline-none transition-all duration-500 ease-in-out hover:text-white hover:shadow-lg hover:shadow-[#e32f77] hover:bg-[#e32f77]`}
      >
        {children}
      </button>
    </>
  );
}

export default ButtonPink;
