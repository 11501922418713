import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { SiMinutemailer } from "react-icons/si";
import { Link } from "react-scroll";
import { Link as LinkTo, useLocation, useNavigate } from "react-router-dom";
import "./index.css";

const ourServices = [
  "Video Editing",
  "Web App Development",
  "Mobile App Development",
  "3D visualization",
];

const menus = ["home", "services", "about", "contact", "teams"];

function Footer() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleServiceClick = (link: string) => {
    if (link === "Web App Development" || link === "Mobile App Development") {
      navigate("web-dev");
    } else if (link === "Video Editing") {
      navigate("video");
    } else {
      navigate("3D");
    }
  };

  return (
    <div className="h-[10rem] pt-4 lg:pt-10 background-bottom overflow-hidden relative lines">
      <div className="grid grid-row-2 divide-y divide-white px-4 lg:px-28 py-6 lg:py-18 ">
        <div className=" py-10">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 py-6 lg:py-10 border-t-2 border-[#73ced8]">
            <div className="lg:ml-10 lg:mt-[-28px] flex justify-center lg:justify-start">
              <img
                src="/images/logo/Dev-Art-Logo-blue.svg"
                className="h-[3rem] lg:h-[6rem]"
              />
            </div>
            <div className="flex justify-around">
              <div>
                <h4 className="text-black lg:text-[#73ced8] text-[14px] md:text-[16px] text uppercase font-['Lato'] font-bold mb-5">
                  OUR SERVICES
                </h4>
                <ul className="grid gap-y-4">
                  {ourServices.map((item) => {
                    return (
                      <li
                        onClick={() => handleServiceClick(item)}
                        className="text-black lg:text-[#a0e1e8] lg:hover:text-[#599da5] relative w-fit under-line-li text-[10px] md:text-[12px] uppercase font-['Montserrat'] font-semibold cursor-pointer"
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div>
                <h4 className="text-black lg:text-[#73ced8]  text-[14px] md:text-[16px] text uppercase font-['Lato'] font-bold mb-5">
                  Social media
                </h4>

                <ul className="grid gap-y-4">
                  <li className="text-black lg:text-[#a0e1e8] lg:hover:text-[#599da5] flex items-center text-[10px] md:text-[12px] uppercase font-['Montserrat'] font-semibold cursor-pointer">
                    <FaPhoneAlt className="mr-2 text-black lg:text-[#4cedff] lg:hover:text-[#599da5] text-[16px]" />{" "}
                    8810490364
                  </li>
                  <li className="text-black lg:text-[#a0e1e8] lg:hover:text-[#599da5] flex items-center text-[10px] md:text-[12px] uppercase font-['Montserrat'] font-semibold cursor-pointer">
                    <MdEmail className="mr-2 text-black lg:text-[#4cedff] lg:hover:text-[#599da5] text-[16px]" />{" "}
                    vkrathore2799@gmail.com
                  </li>
                  <li className="text-black lg:text-[#a0e1e8] lg:hover:text-[#599da5] flex items-center text-[10px] md:text-[12px] uppercase font-['Montserrat'] font-semibold cursor-pointer">
                    <SiMinutemailer className="mr-2 text-black lg:text-[#4cedff] lg:hover:text-[#599da5] text-[18px]" />{" "}
                    telegram
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 py-10">
          <div>
            <ul className="flex flex-col lg:flex-row items-center justify-between">
              {menus.map((item) => {
                return (
                  <>
                    {location.pathname !== "/" ? (
                      <LinkTo
                        to={`/#${item}`}
                        className="text-white lg:text-black relative w-fit under-line-li text-[10px] md:text-[12px]  uppercase font-['Montserrat'] font-semibold cursor-pointer my-2 lg:my-0"
                      >
                        {item}
                      </LinkTo>
                    ) : (
                      <li>
                        <Link
                          id={item}
                          activeClass="activefoot"
                          to={item}
                          spy={true}
                          smooth={true}
                          offset={-82}
                          duration={1000}
                          delay={0}
                          className="text-white lg:text-black relative w-fit under-line-li text-[10px] md:text-[12px]  uppercase font-['Montserrat'] font-semibold cursor-pointer my-2 lg:my-0"
                        >
                          {item}
                        </Link>
                      </li>
                    )}
                  </>
                );
              })}
            </ul>
          </div>
          <div className="flex justify-center lg:justify-end items-center">
            <h4 className="text-white lg:text-slate-500 text-right text-[10px] md:text-[12px] text uppercase font-['Lato'] font-bold ">
              Copyright © 2023 • vishal .
            </h4>
          </div>
        </div>
        <div className="py-10">
          <h4 className="text-white lg:text-slate-500 text-center text-[12px] md:text-[14px]   font-['Lato'] font-bold ">
            Design and Developed by Vishal
          </h4>
        </div>
      </div>
    </div>
  );
}

export default Footer;
