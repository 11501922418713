import Education from "../../core/education";
import Intrest from "../../core/intrest";
import Intro from "../../core/intro";
import Skills from "../../core/skills";
import Specialties from "../../core/specialties";
import WorkExperience from "../../core/work-experience/work-experience";
import { skills, specialties, education, workExp, intrest } from "./work-data";

function VideoEditor() {
  return (
    <div>
      <Intro
        img="/images/teams/arvind.jpg"
        heading="Aravind"
        para1={
          "For the past two years, I have been designing and editing videos. I adore creative work because it's amazing."
        }
        para2={
          " I look at my laptop for almost every moment of the day learning new skills. Throughout the day, the creative industry changes, and I would prefer not to get left behind."
        }
        name={"Arvind"}
        designation={"Video Editor"}
      />
      <Skills
        para={
          "Skilled in crafting seamless visual narratives, enhancing storytelling through precise editing, and employing creative techniques to captivate audiences."
        }
        skills={skills}
      />
      <Specialties
        para={
          "Specializing in transforming raw footage into polished masterpieces, bringing creativity and professionalism to every project."
        }
        specialties={specialties}
      />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pl-4 pr-4 md:pl-24	md:pr-24 pt-9 pb-20">
        <WorkExperience workExp={workExp} />
        <Education education={education} />
      </div>
      <Intrest
        para={
          "Enthusiastic about exploring creative storytelling and discovering inspiration everywhere."
        }
        intrest={intrest}
      />
      <section id="myWork">
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/5ae381175332279.64b17c9a1340b.gif" />
      </section>
    </div>
  );
}

export default VideoEditor;
