import Footer from "./components/footer";
import Navbar from "./components/navbar";
import { Outlet } from "react-router";

const Layout = () => {
  return (
    <>
      <Navbar />

      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
