import { FaReact, FaNode } from "react-icons/fa";
import { MdSportsVolleyball } from "react-icons/md";
import { IoFitnessSharp } from "react-icons/io5";
import { GiBattleMech, GiMechaHead } from "react-icons/gi";

export const skills = [
  { name: "HTML", progress: 90 },
  { name: "CSS", progress: 80 },
  { name: "SASS", progress: 50 },
  { name: "Javascript", progress: 80 },
  { name: "Typescript", progress: 60 },
  { name: "React.js", progress: 90 },
  { name: "Node.js", progress: 70 },
  { name: "Express.js", progress: 65 },
  { name: "MongoDB", progress: 60 },
];

export const specialties = [
  {
    icon: <FaReact className="h-10 w-10 lg:h-16 lg:w-16 text-[#e32f77]" />,
    text: "Front End Development",
  },
  {
    icon: <FaNode className="h-10 w-10 lg:h-16 lg:w-16 text-[#e32f77]" />,
    text: " Back End Development",
  },
];

export const education = [
  {
    time: "2016-2020",
    desg: "Btech(EEE) - GGSIPU",
    info: "Electrical and Electronics Engineering graduate with a passion for software development, equipped with a solid foundation in technical principles and problem-solving skills. ",
  },
  {
    time: "2021-2022",
    desg: "Trainning Cource - Pep Coding",
    info: "Dynamic full stack developer with a year-long intensive training in data structures and algorithms, bolstering logical prowess and coding efficiency. Proven ability to apply advanced concepts in practical coding scenarios, enhancing overall technical proficiency.",
  },
];

export const workExp = [
  {
    time: "2021-2022",
    desg: "Full Stack Developer - Concept to code",
    info: "Experienced full stack developer with a solid foundation in web development, gained valuable skills and expertise through hands-on experience and continuous learning in previous role as a fresher.",
  },
  {
    time: "2022-present",
    desg: "Full Stack Developer - Freelancer",
    info: "Full stack developer with extensive freelance experience, adept at both technical proficiency and client management skills, honed through continuous engagement in diverse web development projects from inception to delivery.",
  },
];

export const intrest = [
  {
    name: "Artificial Intelligence",
    info: "Enthusiastic about diving into artificial intelligence to explore new possibilities and create meaningful solutions.",
    icon: <GiMechaHead className="h-10 w-10 lg:h-16 lg:w-16 text-[#00c2d7]" />,
  },
  {
    name: "Machine Learning",
    info: "Passionate about exploring the realms of machine learning and its applications in solving real-world problems.",
    icon: <GiBattleMech className="h-10 w-10 lg:h-16 lg:w-16 text-[#00c2d7]" />,
  },
  {
    name: "Sports",
    info: "Enthusiastic about staying active through various sports activities.",
    icon: (
      <MdSportsVolleyball className="h-10 w-10 lg:h-16 lg:w-16 text-[#00c2d7]" />
    ),
  },
  {
    name: "Fitness",
    info: "Enthusiastic about maintaining a healthy lifestyle through fitness and gym workouts.",
    icon: (
      <IoFitnessSharp className="h-10 w-10 lg:h-16 lg:w-16 text-[#00c2d7]" />
    ),
  },
];
