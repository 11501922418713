type IntrestProps = {
  para: string;
  intrest: { name: string; info: string; icon: React.ReactNode }[];
};

function Intrest({ para, intrest }: IntrestProps) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pl-4 pr-4 md:pl-24	md:pr-24 pt-9 pb-20 bg-slate-100">
      <div className="flex flex-col justify-center">
        <h2 className="text-[1.5rem] lg:text-[3rem]   font-[800] font-lato whitespace-nowrap">
          <span className="outline-font-dark-blue text-white">MY</span>{" "}
          <span className=" text-[#00c2d7]">INTEREST</span>{" "}
        </h2>
        <p className="font-nunito font-bold text-[13px] lg:text-xl leading-5 lg:leading-[28px] text-[#00c2d7] mt-4">
          {para}
        </p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {intrest.map(({ name, icon, info }) => (
          <div className="my-6 flex">
            <div>{icon} </div>
            <div className="ml-2">
              <p className="font-nunito font-bold  text-[13px] lg:text-[20px] leading-5 lg:leading-[28px] text-[#00c2d7] mt-3 lg:mt-4">
                {name}
              </p>

              <p className="font-nunito  text-[13px] lg:text-[16px] leading-5 lg:leading-[20px] text-[#00c2d7] mt-1">
                {info}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Intrest;
