import { useState } from "react";
import NavbarContext from "./navbar-context";

type NavBarContexProps = {
  children: React.ReactNode;
};

const NavbarContextProvider = ({ children }: NavBarContexProps) => {
  const [bg, setbg] = useState(null);
  return (
    <NavbarContext.Provider value={{ bg, setbg }}>
      {children}
    </NavbarContext.Provider>
  );
};

export default NavbarContextProvider;
