type EducationProps = {
  education: { time: string; desg: string; info: string }[];
};
function Education({ education }: EducationProps) {
  return (
    <div className="flex items-start">
      <h2 className="text-[1.5rem] lg:text-[3rem]   font-[800] font-lato whitespace-nowrap">
        <span className="outline-font-dark text-white">02</span>
      </h2>
      <div className="mt-2 lg:mt-6 ml-3">
        <h2 className="text-[13px] lg:text-[1.5rem] font-[800] font-lato whitespace-nowrap">
          <span className="text-[#e32f77]">Educ</span>
          <span className="text-[#00c2d7]">ation </span>
        </h2>
        {education.map(({ time, desg, info }) => (
          <div className="mb-8">
            <p className="font-nunito   text-[13px] lg:text-[18px] leading-5 lg:leading-[28px]  mt-4">
              <span className="text-[#e32f77]">{time.split("-")[0]} </span> -{" "}
              <span className="text-[#00c2d7]">{time.split("-")[1]} </span>
            </p>
            <p className="font-nunito font-bold  text-[13px] lg:text-[20px] leading-5 lg:leading-[28px]  mt-4">
              <span className="text-[#e32f77]">{desg.split("-")[0]} </span> -{" "}
              <span className="text-[#00c2d7]">{desg.split("-")[1]} </span>
            </p>
            <p className="font-nunito  text-[13px] lg:text-[18px] leading-5 lg:leading-[28px]  mt-1">
              {info}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Education;
