import { useNavigate } from "react-router-dom";
import "../homepage.css";
import { Link } from "react-scroll";

function Banner3() {
  const navigate = useNavigate();
  return (
    <div className="banner3-background relative flex justify-end items-center pr-4  md:pr-24">
      <div className="absolute bg-white lg:bg-transparent h-screen w-screen opacity-80 lg:opacity-100"></div>
      <div className="content flex flex-col items-end ">
        <img
          className="absolute top-0 right-0 w-[9rem]"
          src="/images/banner/Dots.svg"
        />
        <h2 className="z-10 text-[1.85rem] lg:text-[6rem]  font-[800] font-lato whitespace-nowrap">
          <span className="outline-font-pink text-white">3D</span>{" "}
          <span className="text-[#93478f]">VISUALISATION</span>
        </h2>
        <p className="z-10 font-nunito text-[16px] lg:text-xl leading-6 lg:leading-[28px] text-right lg:w-3/4 text-[#93478f]  mt-4">
          Dive into a world where creativity knows no bounds - experience the
          magic of 3D visualization!
        </p>

        <button
          className={`z-10 px-2 pt-2 pb-1 md:px-7 md:py-3 mt-10 text-[11px] md:text-[15px] rounded font-bold text-[#93478f] border border-[#93478f]  focus:outline-none transition-all duration-500 ease-in-out hover:text-white hover:shadow-lg hover:shadow-[#93478f] hover:bg-[#93478f]`}
        >
          <Link
            to={"contact"}
            spy={true}
            smooth={true}
            offset={0}
            duration={1000}
          >
            Conatct Us
          </Link>
        </button>
      </div>
    </div>
  );
}

export default Banner3;
